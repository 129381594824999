import rwxMath from 'roseworx/js/helpers/rwxMathHelpers';
import rwxGeometry from 'roseworx/js/helpers/rwxGeometryHelpers';
import {rwxAnimation, rwxAnimationChain} from 'roseworx/js/modules/rwxAnimation';

class Intro {
  constructor(rose, cb)
  {
    rose.rose.position.set(30, -25, 10);
    rose.mousing = false;
    rose.spinning = false;
    window.rose = rose;
    this.mobile = 860;
    this.yR = 0;
    this.cb = cb;
    this.rose = rose;
    this.container = document.getElementById('intro');
    this.letters = [...this.container.querySelectorAll('h1')];
    this.createAnimations();
    this.animate();
  }

  createAnimations()
  {
    this.startIn = true;
    this.roseAnime = false;
    this.startOut = false;
    this.inAnimations = [];
    this.outAnimations = [];
    this.rotationMatrix = [];
    this.completeAnimations = [];
    this.letters.map((l, i)=>{
      const animation1 = new rwxAnimationChain({
        sequence: [
          {
            from:[-800, 0, 65],
            to:[0, 1, 100],
            easing: 'easeOutBack',
            duration:1000,
            delay: i*300
          }
        ],
        complete: ()=>this.completeAnimations.push(i)
      });

      const animation2 = new rwxAnimation({
        from: [0, 0, 1],
        to: [1200, 360, 0],
        duration: 1500,
        easing: 'easeOutQuad',
        complete: ()=>this.completeAnimations.push(i)
      });

      this.inAnimations.push(animation1);
      this.outAnimations.push(animation2);
      this.rotationMatrix.push([rwxMath.randomFloat(0,1),rwxMath.randomFloat(0,1),rwxMath.randomFloat(0,1)]);
    });
    this.roseAnimation = new rwxAnimationChain({
      sequence: [
        {
          from: [30, -25, 10, 3],
          to: window.innerWidth < this.mobile ? [8, 30, -100, 0] : [38, 25, -100, 0],
          easing: 'easeInOutQuart',
          duration: 3000,
          control: [
            {
              cp1:window.innerWidth < this.mobile ? -40 : -75,
              cp2:window.innerWidth < this.mobile ? -40 : -75
            },
            {
              cp1:window.innerWidth < this.mobile ? -40 : -50,
              cp2:window.innerWidth < this.mobile ? 50: 85
            },
            {
              cp1:-200,
              cp2:-150
            },
            {
              cp1:2,
              cp2:0.3
            }
          ]
        },
        {
          from: [0, window.innerWidth < this.mobile ? 30 : 25, -100],
          to: [0, window.innerWidth < this.mobile ? 35 : 30, -85],
          easing: 'easeOutCubic',
          duration: 3000,
          control: [
            {
              cp1: 15,
              cp2: 10
            },
            {
              cp1: 25,
              cp2: 30
            },
            {
              cp1: -100,
              cp2: -85
            }
          ],
          complete: ()=>this.yR = 0
        },
        {
          from: window.innerWidth < this.mobile ? [8, 35, -85, 0] : [38, 30, -85, 0],
          to: [0, 0, -80, 3],
          easing: 'easeInQuint',
          duration: 500
        }
      ],
      complete: ()=>{
        this.roseAnime = false;
        this.startOut = true;
        this.rose.spinning = true;
      }
    })
  }

  animate()
  {
    if(this.startIn)
    {
      this.inAnimations.map((a,i)=>{
        a.animate([
          (z, o, s)=> {
              this.letters[i].style.transform = `translate3d(0px, 0px, ${z}px) scale(${s/100})`;
              this.letters[i].style.opacity = o;
            }
         ])
      });
      if(this.completeAnimations.length === this.letters.length-4)
      {
        this.roseAnime = true;
      }
      if(this.completeAnimations.length === this.letters.length)
      {
        this.startIn = false;
        this.completeAnimations = [];
      }
    }

    if(this.roseAnime)
    {
      this.roseAnimation.animate([
        (x, y, z, m)=>{
          this.rose.rose.position.set(x,y,z);
          this.rose.rose.rotation.z -= m*2; 
        },
        (r, y, z)=>{
          this.rose.rose.position.y = y;
          this.rose.rose.position.z = z;
          this.yR=r;
        },
        (x, y, z, m)=>{
          this.rose.rose.rotation.z -= m*2;
          this.rose.rose.position.set(x,y,z);
        }
      ]);
      this.rose.rose.rotation.y -= this.yR/20;
    }

    if(this.startOut)
    {
      this.outAnimations.map((a2,ii)=>{
        a2.animate((z, d, o)=>{
          this.letters[ii].style.transform = `translate3d(0px, 0px, ${z}px) rotate3d(${this.rotationMatrix[ii][0]}, ${this.rotationMatrix[ii][1]}, ${this.rotationMatrix[ii][2]}, ${d}deg)`;
          this.letters[ii].style.opacity = o;
        })
      })
      if(this.completeAnimations.length === this.letters.length)
      {
        this.startOut = false;
        this.completeAnimations = [];
        this.rose.mousing = true;
        this.container.parentNode.removeChild(this.container);
        this.cb();
        return;
      }
    }
    window.requestAnimationFrame(this.animate.bind(this));
  }
}

export default Intro;