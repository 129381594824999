import * as THREE from 'three';
import rwxMatrix from 'roseworx/js/modules/rwxMatrix';
import {rwxAnimation, rwxAnimationChain} from 'roseworx/js/modules/rwxAnimation';
import rwxGeometry from 'roseworx/js/helpers/rwxGeometryHelpers';
import rwxMouseTrack from 'roseworx/js/common/rwxMouseTracking';

class Rose extends rwxMatrix {
	constructor()
	{
		super({parent:'rose', lights:true, fov:35, nv:0.1, fv:200, THREEInstance: THREE});
		this.destroyDemo();
		this.createConfig();
		this.generateShape();
		this.tweek();
		this.setInitial();
		this.createMoveAnimation();
		this.mouseTrack = new rwxMouseTrack(window);
	} 

	setInitial()
	{
		this.camera.position.set(0, 0, 50);
		this.directLight.position.set(0, 25, 15);
		this.rose.position.set(this.positions[this.currentPosition].x, this.positions[this.currentPosition].y, this.positions[this.currentPosition].z);
	}

	rotate(sy)
	{
		this.zRotation = (sy/150);
	}

	createMoveAnimation(){
		this.moveAnimation = new rwxAnimation({
			from: [
				()=>this.getCurrentPosition('current').x,
				()=>this.getCurrentPosition('current').y,
				()=>this.getCurrentPosition('current').z,
			],
			to: [
				()=>this.getCurrentPosition('next').x,
				()=>this.getCurrentPosition('next').y,
				()=>this.getCurrentPosition('next').z,
			],
			easing: 'easeInElastic',
			duration: this.moveDuration,
			complete: ()=>{this.runmoveAnimation=false; this.moveAnimation.reset(); this.currentPosition = this.nextPosition;}
		});
	}

	getCurrentPosition(which)
	{
		if(which === "current")
		{
			return this.rose.position;
		}
		else if(which === "next")
		{
			return this.positions[this.nextPosition];
		}
	}

	moveObject(to)
	{
		if(to === this.nextPosition)
		{
			return;
		}
		else
		{
			this.runmoveAnimation = false;
			this.createMoveAnimation();
		}
		this.nextPosition = to;
		this.runmoveAnimation = true;
	}

	tweek()
	{
		this.directLight.color = new this.THREE.Color(0xFFFFFF);
		this.directLight.intensity = 7;
		this.ambience.intensity = 2;
	 	this.ambience.color = new this.THREE.Color(0x505050);
	 	this.renderer.physicallyCorrectLights = true;
	}

	createConfig()
	{
		this.currentPosition = "topLeft";
		this.rotationNorm = 0.005;
		this.moveDuration = 2000;
    this.mouseMoveDrag = 0.02;
    this.mouseMoveBounds = 0.0001;
		this.zRotation = 0;
		this.yRotation = this.rotationNorm;
		this.positions = {
			center: new this.THREE.Vector3(0, 0, 0),
			right: new this.THREE.Vector3((window.innerWidth/100), 0, 0),
			left: new this.THREE.Vector3(-(window.innerWidth/100), 0, 0),
			topLeft: new this.THREE.Vector3(-15, 15, 10),
			centerBack: new this.THREE.Vector3(0, 0, -80),
		}
	}

	generateShape()
	{
    const mainObjectColor = new this.THREE.Color( 0xf47740 );
    mainObjectColor.convertSRGBToLinear();
    const mainObject = new this.THREE.TorusKnotBufferGeometry( 2, 3.6, 64, 10, 8, 17 );
    const mainObjectMaterial = new this.THREE.MeshStandardMaterial( { color: mainObjectColor, flatShading: true } );
		this.rose = this.addShape(mainObject, mainObjectMaterial);
	}

	animate()
	{
		if(this.runmoveAnimation)
		{
			this.moveAnimation.animate((x, y, z)=>{
				this.rose.position.set(x,y,z);
			})
		}
		if(this.mousing)
		{
      this.camera.rotation.x += this.mouseMoveDrag * ( (( 1 - this.mouseTrack.parallaxmouse.y ) * this.mouseMoveBounds) - this.camera.rotation.x );
      this.camera.rotation.y += this.mouseMoveDrag * ( (( 1 - this.mouseTrack.parallaxmouse.x ) * this.mouseMoveBounds) - this.camera.rotation.y );
		}
		if(this.spinning)
		{
	    this.rose.rotation.z = this.zRotation;
	    this.rose.rotation.y += this.yRotation;
		}
	}
}

export default Rose;