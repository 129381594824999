import {rwxAnimationChain} from 'roseworx/js/modules/rwxAnimation';
import rwxMath from 'roseworx/js/helpers/rwxMathHelpers';

class Projects {
	constructor()
	{
		this.closeEvent = this.closeEvent.bind(this);
		this.animate = this.animate.bind(this);
		let projects = [...document.querySelectorAll('[data-open-project]')];
		for(let project of projects)
		{
			if(!project)continue;
			let toOpen = document.getElementById(project.getAttribute('data-open-project'));
			if(!toOpen)continue;
			let c = document.createElement('div');
			c.classList.add('close');
			c.addEventListener('click', ()=>this.closeEvent({target:document.body}));
			toOpen.appendChild(c);
			project.addEventListener('click', (e)=>{
				e.preventDefault();
				toOpen && this.doAnimation(toOpen, e.target);
			})
		}
		this.clicked = false;
		this.createAnimation();
	}

	doAnimation(p, t)
	{
		if(this.animating){return;}
		this.clickedElement = t;
		this.activeProject = p;
		this.openAnimation.reset();
		this.closeAnimation.reset();
		this.runOpenAnimation = true;
		this.stopAnimation = false;
		this.animating = true;
		this.animate();
	}

	animate()
	{
		if(this.runOpenAnimation)
		{
			this.openAnimation.animate([
				(l, t)=>{
					this.activeProject.style.left = l+"px";
					this.activeProject.style.top = t+"px";
				},
				(s)=>{
					this.activeProject.style.width = s+"%";
					this.activeProject.style.height = s+"%";
				}
			]);
		}
		if(this.runCloseAnimation)
		{
			this.closeAnimation.animate([
				(s)=>{
					this.activeProject.style.width = s+"%";
					this.activeProject.style.height = s+"%";				
				},
				(p)=>{
					this.activeProject.style.left = p+"px";
					this.activeProject.style.top = p+"px";
				},
			])
		}
		if(this.stopAnimation)return
		window.requestAnimationFrame(this.animate);
	}

	closeEvent(e)
	{
		if(!this.clicked && e.target !== this.activeProject && !this.activeProject.contains(e.target))
		{
			this.clicked = true;
			this.runCloseAnimation = true;
			this.stopAnimation = false;
			this.animate();
			this.activeProject.classList.remove('open');
		}
	}

	getDimensions(what)
	{
		let bounds = this.clickedElement.getBoundingClientRect();
		return what === "x" ? bounds.left : bounds.top;
	}

	getDimensions2(what)
	{
		let bounds = this.activeProject.getBoundingClientRect();
		return what === "x" ? bounds.left : bounds.top;
	}

	createAnimation()
	{
		this.openAnimation = new rwxAnimationChain({
			sequence: [
				{
					from: [()=>this.getDimensions('x'), ()=>this.getDimensions('y')],
					to: [(window.innerWidth/10), (window.innerHeight/10)],
					control: [
						{cp1:rwxMath.randomInt(0, window.innerWidth), cp2:rwxMath.randomInt(0, window.innerHeight)},
						{cp1:rwxMath.randomInt(0, window.innerWidth), cp2:rwxMath.randomInt(0, window.innerHeight)}
					],
					duration: 1000,
					easing: 'easeOutQuart',
					complete:()=>this.activeProject.classList.add('open')
				},
				{
					from: 0,
					to: 80,
					duration: 500,
					easing: 'easeInOutQuad'
				}
			],
			complete: ()=>{
				this.runOpenAnimation = false;
				this.stopAnimation = true;
				document.addEventListener('click', this.closeEvent);
			}
		});

		this.closeAnimation = new rwxAnimationChain({
			sequence: [
				{
					from: 80,
					to: 0,
					duration: 500,
					easing: 'easeOutQuint'
				},
				{
					from: [()=>this.getDimensions2('x'), ()=>this.getDimensions2('y')],
					to: [-100, -100],
					duration: 500,
					easing: 'easeOutQuint'
				}
			],
			complete: ()=>{
				document.removeEventListener('click', this.closeEvent);
				this.stopAnimation = true;
				this.runCloseAnimation = false;
				this.clicked = false;
				this.animating = false;
			}
		})
	}

}

export default Projects;