import 'core-js';
import "regenerator-runtime/runtime";
require('../scss/index.scss');
import rwxPreload from 'roseworx/js/modules/rwxPreload';

import rwxBitSystems from 'roseworx/js/bitfx/rwxBitSystems';
import rwxBitBlackHoles from 'roseworx/js/bitfx/rwxBitBlackHoles';
import rwxGridGalleries from 'roseworx/js/components/rwxGridGalleries';
import rwxSkrollX from 'roseworx/js/modules/rwxSkrollX';
import rwxTimelines from 'roseworx/js/components/rwxTimelines';

import rwxSkrollHighjack from 'roseworx/js/modules/rwxSkrollHighjack';
import Rose from './Rose';
import Projects from './Projects';
import Intro from './Intro';

const debug = false;

window.addEventListener('DOMContentLoaded', ()=>{
	const scrollIcon = document.getElementById('scroll-icon');
	scrollIcon.style.display = "none";
});

window.addEventListener('load', ()=>{
	const rose = new Rose();
	new Projects();
	document.getElementById('age').innerText = Math.abs(new Date(new Date()-new Date('08/11/1995')).getUTCFullYear() - 1970);
	document.getElementById('init-loading').parentNode.style.display = "none";
	const scrollIcon = document.getElementById('scroll-icon');
	const blackHoleContainer = document.getElementById('black-hole-container');
	const scrollContainer = document.getElementById('scroll-container');
	const bh = document.getElementById('black-hole-container');
	const transitions = [...document.querySelectorAll('section[data-move-rose-to]')];
	const mappings = transitions.map((t)=>{
		return {scrollto: t, to: t.getAttribute('data-move-rose-to')}
	});
	const halfScreen = window.innerHeight/2;
	let commenced = false;
	let stopped2 = false;
	let started2 = false;
	let done = false;
	const roseSkroll = (st) => {
		rose.rotate(st);
		if(st > window.innerHeight-(window.innerHeight/2))
		{
			scrollIcon.style.display = "none";
		}
		else
		{
			scrollIcon.style.display = "";
		}
		//prevents the black hole animation loop from running when it is not in view, saving processing power
		if(blackHoleContainer.getBoundingClientRect().top <= 200 && !started2)
		{
			!debug && rwxBitSystems.explode('system');
			!debug && rwxBitBlackHoles.play('black-hole');
			stopped2 = false;
			started2 = true;
		}
		else if(blackHoleContainer.getBoundingClientRect().top > 200 && started2 && !stopped2)
		{
			!debug && rwxBitSystems.implode('system');
			!debug && rwxBitBlackHoles.pause('black-hole');
			stopped2 = true;
			started2 = false;		
		}

		// moves the rose to the position specified in the data attribute of the element wheneever that element is scrolled in to view
		for(let [index,map] of mappings.entries())
		{
			let nextCond = mappings[index+1] ? mappings[index+1].scrollto.getBoundingClientRect().top < window.innerHeight : false;
			let inView = map.scrollto.getBoundingClientRect().top < window.innerHeight;
			if(inView && !nextCond)
			{
				rose.moveObject(map.to, true);
			}
		}
	}
	const cb = ()=>{
		rwxBitSystems.play('system');
		new rwxSkrollHighjack(true, [...document.querySelectorAll('[data-scrollable-content]')], roseSkroll);
		scrollIcon.style.display = "";
	};

	if(debug) {
		cb();
		return;
	}
	const intro = new Intro(rose, cb);
});